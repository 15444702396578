import React, { useEffect, useState } from 'react';
import { Typography, Box, Grid, Paper } from '@mui/material';
import { Line, Bar, Doughnut, Radar } from 'react-chartjs-2';
import axios from 'axios';
import jwtDecode from 'jwt-decode';
import 'chart.js/auto';

const logoGreen = "#6cc24a"; // Color from your logo
const cardBackground = "#ffffff"; // Card background color
const textColor = "#333333"; // Dark text color
const fontFamily = "'Poppins', sans-serif"; // Poppins font

const Dashboard = () => {
    const [messageStats, setMessageStats] = useState([]);
    const [groupStats, setGroupStats] = useState([]);
    const [participantStats, setParticipantStats] = useState([]);
    const [totalMessages, setTotalMessages] = useState(0);
    const [userCount, setUserCount] = useState(0);
    const [userMessageStats, setUserMessageStats] = useState([]);
    const [isAdmin, setIsAdmin] = useState(false);

    useEffect(() => {
        const token = localStorage.getItem('token');
        if (token) {
            const decodedToken = jwtDecode(token);
            setIsAdmin(decodedToken.id === 1); // Check if the user is an admin
        }
        fetchData();
    }, []);

    const fetchData = async () => {
        try {
            const token = localStorage.getItem('token');

            const messageResponse = await axios.get('https://dev.zapfly.pro/message-stats', {
                headers: { 'Authorization': `Bearer ${token}` }
            });
            setMessageStats(messageResponse.data);

            const groupResponse = await axios.get('https://dev.zapfly.pro/group-stats', {
                headers: { 'Authorization': `Bearer ${token}` }
            });
            setGroupStats(groupResponse.data);

            const participantResponse = await axios.get('https://dev.zapfly.pro/participant-stats', {
                headers: { 'Authorization': `Bearer ${token}` }
            });
            setParticipantStats(participantResponse.data);

            const totalMessagesResponse = await axios.get('https://dev.zapfly.pro/total-messages', {
                headers: { 'Authorization': `Bearer ${token}` }
            });
            setTotalMessages(totalMessagesResponse.data.total);

            if (isAdmin) {
                const userCountResponse = await axios.get('https://dev.zapfly.pro/user-count', {
                    headers: { 'Authorization': `Bearer ${token}` }
                });
                setUserCount(userCountResponse.data.count);
            }

            const userMessageStatsResponse = await axios.get('https://dev.zapfly.pro/history', {
                headers: { 'Authorization': `Bearer ${token}` }
            });
            setUserMessageStats(userMessageStatsResponse.data);

        } catch (error) {
            console.error('Error fetching data:', error);
        }
    };

    const messageData = {
        labels: messageStats.map(stat => stat.time),
        datasets: [
            {
                label: 'Mensagens ao longo do tempo',
                data: messageStats.map(stat => stat.count),
                fill: false,
                backgroundColor: 'rgba(75,192,192,0.2)',
                borderColor: logoGreen,
                tension: 0.4,
                pointRadius: 5,
                pointBackgroundColor: '#ffffff',
                pointBorderWidth: 2,
                pointHoverRadius: 7,
            },
        ],
    };

    const groupData = {
        labels: groupStats.map(stat => stat.group),
        datasets: [
            {
                label: 'Quantidade de Grupos',
                data: groupStats.map(stat => stat.count),
                backgroundColor: `linear-gradient(90deg, ${logoGreen} 0%, #67d18b 100%)`,
                borderColor: logoGreen,
                borderWidth: 1,
                borderRadius: 5,
                hoverBackgroundColor: '#67d18b',
            },
        ],
    };

    const participantData = {
        labels: participantStats.map(stat => stat.group),
        datasets: [
            {
                label: 'Participantes por Grupo',
                data: participantStats.map(stat => stat.count),
                backgroundColor: participantStats.map(() => logoGreen),
                hoverBackgroundColor: participantStats.map(() => '#67d18b'),
            },
        ],
    };

    const totalMessagesData = {
        labels: ['Total de Mensagens'],
        datasets: [
            {
                label: 'Mensagens',
                data: [totalMessages],
                backgroundColor: [logoGreen],
                hoverBackgroundColor: ['#67d18b'],
                borderWidth: 1,
            },
        ],
    };

    const userCountData = {
        labels: ['Total de Usuários'],
        datasets: [
            {
                label: 'Usuários',
                data: [userCount],
                backgroundColor: [logoGreen],
                hoverBackgroundColor: ['#67d18b'],
                borderWidth: 1,
            },
        ],
    };

    // Data for the new chart displaying messages sent by users per hour
    const userMessageData = {
        labels: userMessageStats.map(stat => stat.time), // Assuming `time` is in local server time
        datasets: [
            {
                label: 'Mensagens por Hora',
                data: userMessageStats.map(stat => stat.count),
                backgroundColor: logoGreen,
                borderColor: '#333333',
                borderWidth: 1,
                borderRadius: 5,
                hoverBackgroundColor: '#67d18b',
            },
        ],
    };

    return (
        <Box sx={{ flexGrow: 1, p: 3, fontFamily, minHeight: '100vh' }}>
            <Typography variant="h4" gutterBottom sx={{ color: textColor, fontFamily, fontWeight: 600 }}>
                Dashboard
            </Typography>
            <Grid container spacing={3}>
                <Grid item xs={12} md={6}>
                    <Box sx={{ boxShadow: 4, borderRadius: 2 }}>
                        <Paper sx={{ padding: 3, backgroundColor: cardBackground }}>
                            <Typography variant="h6" sx={{ color: textColor, fontFamily, fontWeight: 500 }}>
                                Mensagens ao longo do tempo
                            </Typography>
                            <Box sx={{ height: 300 }}>
                                <Line data={messageData} options={{ maintainAspectRatio: false }} />
                            </Box>
                        </Paper>
                    </Box>
                </Grid>
                <Grid item xs={12} md={6}>
                    <Box sx={{ boxShadow: 4, borderRadius: 2 }}>
                        <Paper sx={{ padding: 3, backgroundColor: cardBackground }}>
                            <Typography variant="h6" sx={{ color: textColor, fontFamily, fontWeight: 500 }}>
                                Quantidade de Grupos
                            </Typography>
                            <Box sx={{ height: 300 }}>
                                <Bar data={groupData} options={{ maintainAspectRatio: false }} />
                            </Box>
                        </Paper>
                    </Box>
                </Grid>
                <Grid item xs={12} md={6}>
                    <Box sx={{ boxShadow: 4, borderRadius: 2 }}>
                        <Paper sx={{ padding: 3, backgroundColor: cardBackground }}>
                            <Typography variant="h6" sx={{ color: textColor, fontFamily, fontWeight: 500 }}>
                                Participantes por Grupo
                            </Typography>
                            <Box sx={{ height: 300 }}>
                                <Doughnut data={participantData} options={{ maintainAspectRatio: false }} />
                            </Box>
                        </Paper>
                    </Box>
                </Grid>
                <Grid item xs={12} md={6}>
                    <Box sx={{ boxShadow: 4, borderRadius: 2 }}>
                        <Paper sx={{ padding: 3, backgroundColor: cardBackground }}>
                            <Typography variant="h6" sx={{ color: textColor, fontFamily, fontWeight: 500 }}>
                                Total de Mensagens
                            </Typography>
                            <Box sx={{ height: 300 }}>
                                <Radar data={totalMessagesData} options={{ maintainAspectRatio: false }} />
                            </Box>
                        </Paper>
                    </Box>
                </Grid>
                {isAdmin && (
                    <Grid item xs={12} md={6}>
                        <Box sx={{ boxShadow: 4, borderRadius: 2 }}>
                            <Paper sx={{ padding: 3, backgroundColor: cardBackground }}>
                                <Typography variant="h6" sx={{ color: textColor, fontFamily, fontWeight: 500 }}>
                                    Total de Usuários
                                </Typography>
                                <Box sx={{ height: 300 }}>
                                    <Doughnut data={userCountData} options={{ maintainAspectRatio: false }} />
                                </Box>
                            </Paper>
                        </Box>
                    </Grid>
                )}
                <Grid item xs={12} md={6}>
                    <Box sx={{ boxShadow: 4, borderRadius: 2 }}>
                        <Paper sx={{ padding: 3, backgroundColor: cardBackground }}>
                            <Typography variant="h6" sx={{ color: textColor, fontFamily, fontWeight: 500 }}>
                                Mensagens por Hora
                            </Typography>
                            <Box sx={{ height: 300 }}>
                                <Bar data={userMessageData} options={{ maintainAspectRatio: false }} />
                            </Box>
                        </Paper>
                    </Box>
                </Grid>
            </Grid>
        </Box>
    );
};

export default Dashboard;
