import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Box, TextField, Button, Typography, Snackbar, Alert, Dialog, DialogTitle, DialogContent, DialogActions, Autocomplete, Grid, Paper } from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';

const logoGreen = "#6cc24a"; // Cor dos ícones e botões
const cardBackground = "#ffffff"; // Cor de fundo dos cards
const textColor = "#333333"; // Cor do texto
const fontFamily = "'Poppins', sans-serif"; // Fonte Poppins

const RegisterUser = () => {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [users, setUsers] = useState([]);
    const [selectedUser, setSelectedUser] = useState(null);
    const [openSnackbar, setOpenSnackbar] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState('');
    const [snackbarSeverity, setSnackbarSeverity] = useState('success');
    const [openEditDialog, setOpenEditDialog] = useState(false);
    const [newPassword, setNewPassword] = useState('');
    const [cities, setCities] = useState([]);
    const [selectedCity, setSelectedCity] = useState(null);
    const [newCity, setNewCity] = useState(null);

    useEffect(() => {
        fetchUsers();
        fetchCities();
    }, []);

    const fetchUsers = async () => {
        try {
            const token = localStorage.getItem('token'); // Get JWT token
            const response = await axios.get('https://dev.zapfly.pro/usuarios-com-cidades', {
                headers: { 'x-access-token': token }
            });
            setUsers(response.data);
        } catch (error) {
            console.error('Erro ao buscar usuários:', error);
        }
    };

    const fetchCities = async () => {
        try {
            const token = localStorage.getItem('token'); // Get JWT token
            const response = await axios.get('https://dev.zapfly.pro/cities', {
                headers: { 'x-access-token': token }
            });
            setCities(response.data);
        } catch (error) {
            console.error('Erro ao buscar cidades:', error);
        }
    };

    const handleRegister = async () => {
        try {
            const token = localStorage.getItem('token'); // Get JWT token
            const registerResponse = await axios.post('https://dev.zapfly.pro/register', {
                email,
                password
            }, {
                headers: { 'x-access-token': token }
            });

            if (registerResponse.data.id && selectedCity) {
                await axios.post('https://dev.zapfly.pro/usuario-cidade-plano-disparo', {
                    user_id: registerResponse.data.id,
                    city_id: selectedCity.id
                }, {
                    headers: { 'x-access-token': token }
                });
            }

            setSnackbarMessage('Usuário registrado com sucesso');
            setSnackbarSeverity('success');
            setOpenSnackbar(true);
            fetchUsers(); // Refresh the user list
        } catch (error) {
            console.error('Erro ao registrar usuário:', error);
            setSnackbarMessage('Erro ao registrar usuário');
            setSnackbarSeverity('error');
            setOpenSnackbar(true);
        }
    };

    const handleSnackbarClose = () => {
        setOpenSnackbar(false);
    };

    const handleEditUser = async () => {
        try {
            const token = localStorage.getItem('token'); // Get JWT token
            await axios.put(`https://dev.zapfly.pro/users/${selectedUser.id}`, {
                password: newPassword
            }, {
                headers: { 'x-access-token': token }
            });

            if (selectedUser.city_id !== newCity.id) {
                await axios.post('https://dev.zapfly.pro/usuario-cidade-plano-disparo', {
                    user_id: selectedUser.id,
                    city_id: newCity.id
                }, {
                    headers: { 'x-access-token': token }
                });
            }

            setSnackbarMessage('Dados do usuário atualizados com sucesso');
            setSnackbarSeverity('success');
            setOpenSnackbar(true);
            setOpenEditDialog(false);
            fetchUsers(); // Refresh the user list
        } catch (error) {
            console.error('Erro ao atualizar dados do usuário:', error);
            setSnackbarMessage('Erro ao atualizar dados do usuário');
            setSnackbarSeverity('error');
            setOpenSnackbar(true);
        }
    };

    const handleDeleteUser = async (id) => {
        try {
            const token = localStorage.getItem('token'); // Get JWT token
            await axios.delete(`https://dev.zapfly.pro/users/${id}`, {
                headers: { 'x-access-token': token }
            });
            setSnackbarMessage('Usuário removido com sucesso');
            setSnackbarSeverity('success');
            setOpenSnackbar(true);
            fetchUsers(); // Refresh the user list
        } catch (error) {
            console.error('Erro ao remover usuário:', error);
            setSnackbarMessage('Erro ao remover usuário');
            setSnackbarSeverity('error');
            setOpenSnackbar(true);
        }
    };

    const columns = [
        { field: 'id', headerName: 'ID', width: 70 },
        { field: 'email', headerName: 'Email', width: 200 },
        { field: 'city_name', headerName: 'Cidade', width: 150 },
        {
            field: 'actions',
            headerName: 'Ações',
            width: 200,
            renderCell: (params) => (
                <Box display="flex">
                    <Button
                        variant="contained"
                        sx={{
                            backgroundColor: logoGreen,
                            color: '#fff',
                            fontFamily,
                            '&:hover': {
                                backgroundColor: '#5aa437',
                            },
                            marginRight: 2
                        }}
                        size="small"
                        onClick={() => {
                            setSelectedUser(params.row);
                            setNewPassword('');
                            setNewCity(params.row.city_id ? cities.find(city => city.id === params.row.city_id) : null);
                            setOpenEditDialog(true);
                        }}
                    >
                        Editar
                    </Button>
                    <Button
                        variant="contained"
                        sx={{
                            backgroundColor: '#f44336',
                            color: '#fff',
                            fontFamily,
                            '&:hover': {
                                backgroundColor: '#d32f2f',
                            }
                        }}
                        size="small"
                        onClick={() => handleDeleteUser(params.row.id)}
                    >
                        Remover
                    </Button>
                </Box>
            ),
        },
    ];

    return (
        <Box display="flex" flexDirection="column" alignItems="center" mt={4} p={2} width="100%">
            <Paper elevation={3} sx={{ padding: 4, borderRadius: 2, backgroundColor: cardBackground, boxShadow: '0 3px 10px rgba(0,0,0,0.1)', width: '100%', maxWidth: 1200 }}>
                <Typography variant="h4" sx={{ fontFamily, color: textColor, fontWeight: 600, textAlign: 'center', mb: 3 }}>
                    Registrar Novo Usuário
                </Typography>
                <Grid container spacing={2}>
                    <Grid item xs={12} sm={6}>
                        <TextField
                            label="Email"
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                            margin="normal"
                            variant="outlined"
                            fullWidth
                            sx={{ fontFamily }}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <TextField
                            label="Senha"
                            type="password"
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                            margin="normal"
                            variant="outlined"
                            fullWidth
                            sx={{ fontFamily }}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <Autocomplete
                            options={cities}
                            getOptionLabel={(option) => option.city_name}
                            onChange={(event, newValue) => setSelectedCity(newValue)}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    variant="outlined"
                                    label="Cidade"
                                    placeholder="Selecione a cidade"
                                    margin="normal"
                                    fullWidth
                                    sx={{ fontFamily }}
                                />
                            )}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <Button 
                            variant="contained" 
                            sx={{
                                backgroundColor: logoGreen,
                                color: '#fff',
                                fontFamily,
                                '&:hover': {
                                    backgroundColor: '#5aa437',
                                },
                                fontWeight: 'bold',
                                minWidth: 160,
                                padding: '10px 20px'
                            }}
                            onClick={handleRegister}
                        >
                            Registrar
                        </Button>
                    </Grid>
                </Grid>
                <Box sx={{ height: 400, width: '100%', mt: 4 }}>
                    <DataGrid
                        rows={users}
                        columns={columns}
                        pageSize={5}
                        rowsPerPageOptions={[5]}
                        sx={{
                            '& .MuiDataGrid-columnHeaders': {
                                backgroundColor: logoGreen,
                                color: '#fff',
                                fontFamily,
                            },
                            '& .MuiDataGrid-cell': {
                                fontFamily,
                            }
                        }}
                    />
                </Box>
            </Paper>
            <Snackbar open={openSnackbar} autoHideDuration={6000} onClose={handleSnackbarClose}>
                <Alert onClose={handleSnackbarClose} severity={snackbarSeverity} sx={{ width: '100%', fontFamily }}>
                    {snackbarMessage}
                </Alert>
            </Snackbar>
            <Dialog open={openEditDialog} onClose={() => setOpenEditDialog(false)}>
                <DialogTitle sx={{ fontFamily }}>Editar Usuário</DialogTitle>
                <DialogContent>
                    <TextField
                        label="Nova Senha"
                        type="password"
                        value={newPassword}
                        onChange={(e) => setNewPassword(e.target.value)}
                        margin="normal"
                        variant="outlined"
                        fullWidth
                        sx={{ fontFamily }}
                    />
                    <Autocomplete
                        options={cities}
                        getOptionLabel={(option) => option.city_name}
                        value={newCity}
                        onChange={(event, newValue) => setNewCity(newValue)}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                variant="outlined"
                                label="Cidade"
                                placeholder="Selecione a cidade"
                                margin="normal"
                                fullWidth
                                sx={{ fontFamily }}
                            />
                        )}
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setOpenEditDialog(false)} color="primary" sx={{ fontFamily }}>
                        Cancelar
                    </Button>
                    <Button onClick={handleEditUser} color="primary" sx={{ fontFamily }}>
                        Salvar
                    </Button>
                </DialogActions>
            </Dialog>
        </Box>
    );
};

export default RegisterUser;
