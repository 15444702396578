import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Box, TextField, Button, Typography, Snackbar, Alert, Grid, Paper } from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';

const logoGreen = "#6cc24a"; // Cor dos ícones e botões
const cardBackground = "#ffffff"; // Cor de fundo dos cards
const textColor = "#333333"; // Cor do texto
const fontFamily = "'Poppins', sans-serif"; // Fonte Poppins

const AddCities = () => {
    const [cityName, setCityName] = useState('');
    const [cities, setCities] = useState([]);
    const [openSnackbar, setOpenSnackbar] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState('');
    const [snackbarSeverity, setSnackbarSeverity] = useState('success');

    useEffect(() => {
        fetchCities();
    }, []);

    const fetchCities = async () => {
        try {
            const token = localStorage.getItem('token'); // Get JWT token
            const response = await axios.get('https://api.zapfly.pro/cities', {
                headers: { 'x-access-token': token }
            });
            setCities(response.data);
        } catch (error) {
            console.error('Erro ao buscar cidades:', error);
        }
    };

    const handleAddCity = async () => {
        try {
            const token = localStorage.getItem('token'); // Get JWT token
            await axios.post('https://api.zapfly.pro/add-city', {
                city_name: cityName
            }, {
                headers: { 'x-access-token': token }
            });
            setSnackbarMessage('Cidade adicionada com sucesso');
            setSnackbarSeverity('success');
            setOpenSnackbar(true);
            fetchCities(); // Refresh the city list
        } catch (error) {
            console.error('Erro ao adicionar cidade:', error);
            setSnackbarMessage('Erro ao adicionar cidade');
            setSnackbarSeverity('error');
            setOpenSnackbar(true);
        }
    };

    const handleSnackbarClose = () => {
        setOpenSnackbar(false);
    };

    const columns = [
        { field: 'id', headerName: 'ID', width: 70 },
        { field: 'city_name', headerName: 'Nome da Cidade', width: 200 },
    ];

    return (
        <Box display="flex" flexDirection="column" alignItems="center" mt={4} p={2} width="100%">
            <Paper elevation={3} sx={{ padding: 4, borderRadius: 2, backgroundColor: cardBackground, boxShadow: '0 3px 10px rgba(0,0,0,0.1)', width: '100%', maxWidth: 1200 }}>
                <Typography variant="h4" sx={{ fontFamily, color: textColor, fontWeight: 600, textAlign: 'center', mb: 3 }}>
                    Adicionar Cidade
                </Typography>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <TextField
                            label="Nome da Cidade"
                            value={cityName}
                            onChange={(e) => setCityName(e.target.value)}
                            margin="normal"
                            variant="outlined"
                            fullWidth
                            sx={{ fontFamily }}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <Button 
                            variant="contained" 
                            sx={{
                                backgroundColor: logoGreen,
                                color: '#fff',
                                fontFamily,
                                '&:hover': {
                                    backgroundColor: '#5aa437',
                                },
                                fontWeight: 'bold',
                                minWidth: 160,
                                padding: '10px 20px'
                            }}
                            onClick={handleAddCity}
                        >
                            Adicionar Cidade
                        </Button>
                    </Grid>
                </Grid>
                <Box sx={{ height: 400, width: '100%', mt: 4 }}>
                    <DataGrid
                        rows={cities}
                        columns={columns}
                        pageSize={5}
                        rowsPerPageOptions={[5]}
                        sx={{
                            '& .MuiDataGrid-columnHeaders': {
                                backgroundColor: logoGreen,
                                color: '#fff',
                                fontFamily,
                            },
                            '& .MuiDataGrid-cell': {
                                fontFamily,
                            }
                        }}
                    />
                </Box>
            </Paper>
            <Snackbar open={openSnackbar} autoHideDuration={6000} onClose={handleSnackbarClose}>
                <Alert onClose={handleSnackbarClose} severity={snackbarSeverity} sx={{ width: '100%', fontFamily }}>
                    {snackbarMessage}
                </Alert>
            </Snackbar>
        </Box>
    );
};

export default AddCities;
