import React from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import { CssBaseline, Box, Toolbar } from '@mui/material';
import Dashboard from './components/Dashboard';
import SendMessage from './components/SendMessage';
import MessageViewer from './components/MessageViewer';
import SignIn from './components/SignIn';
import History from './components/History';
import Sidebar from './components/Sidebar';
import QRCodeReader from './components/QRCodeReader';
import RegisterUser from './components/RegisterUser';
import AddCities from './components/AddCities';
import AddGroups from './components/AddGroups';
import AutoCadastro from './components/AutoCadastro';
import Header from './components/Header';  // Import the Header component
import MeuPerfil from './components/MeuPerfil';  // Import meu perfil

import jwtDecode from 'jwt-decode';

function App() {
  const isAuthenticated = !!localStorage.getItem('token');
  const userId = isAuthenticated ? jwtDecode(localStorage.getItem('token')).id : null;

  // Check if the current path is login or auto-cadastro to conditionally render the Sidebar and Header
  const currentPath = window.location.pathname;
  const showSidebarAndHeader = isAuthenticated && !['/login', '/auto-cadastro'].includes(currentPath);

  return (
    <Router>
      <CssBaseline />
      <Box sx={{ display: 'flex' }}>
        {showSidebarAndHeader && <Sidebar />}
        <Box component="main" sx={{ flexGrow: 1, bgcolor: 'background.default' }}>
          {showSidebarAndHeader && <Header />}
          {showSidebarAndHeader && <Toolbar />} {/* This ensures the content starts below the Header */}
          <Box sx={{ p: 3 }}>
            <Routes>
              <Route path="/login" element={<SignIn />} />
              <Route path="/dashboard" element={isAuthenticated ? <Dashboard /> : <Navigate to="/login" />} />
              <Route path="/send-message" element={isAuthenticated ? <SendMessage /> : <Navigate to="/login" />} />
              <Route path="/messages" element={isAuthenticated ? <MessageViewer /> : <Navigate to="/login" />} />
              <Route path="/history" element={isAuthenticated ? <History /> : <Navigate to="/login" />} />
              <Route path="/qr-reader" element={isAuthenticated && userId === 1 ? <QRCodeReader /> : <Navigate to="/login" />} />
              <Route path="/register-user" element={isAuthenticated && userId === 1 ? <RegisterUser /> : <Navigate to="/login" />} />
              <Route path="/add-cities" element={isAuthenticated && userId === 1 ? <AddCities /> : <Navigate to="/login" />} />
              <Route path="/add-groups" element={isAuthenticated && userId === 1 ? <AddGroups /> : <Navigate to="/login" />} />
              <Route path="/meu-perfil" element={isAuthenticated ? <MeuPerfil /> : <Navigate to="/login" />} />

              <Route path="/auto-cadastro" element={<AutoCadastro />} />
              <Route path="/" element={<Navigate to={isAuthenticated ? "/dashboard" : "/login"} />} />
            </Routes>
          </Box>
        </Box>
      </Box>
    </Router>
  );
}

export default App;
