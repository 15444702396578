import React, { useState, useEffect } from 'react';
import axios from 'axios';
import {
    Box, TextField, Button, Typography, Snackbar, Alert,
    Autocomplete, Dialog, DialogActions, DialogContent, DialogTitle,
    Grid, Paper
} from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';

const logoGreen = "#6cc24a"; // Cor dos ícones e botões
const cardBackground = "#ffffff"; // Cor de fundo dos cards
const textColor = "#333333"; // Cor do texto
const fontFamily = "'Poppins', sans-serif"; // Fonte Poppins

const AddGroups = () => {
    const [groupId, setGroupId] = useState('');
    const [groupName, setGroupName] = useState('');
    const [city, setCity] = useState(null);
    const [groups, setGroups] = useState([]);
    const [cities, setCities] = useState([]);
    const [openSnackbar, setOpenSnackbar] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState('');
    const [snackbarSeverity, setSnackbarSeverity] = useState('success');
    const [openEditDialog, setOpenEditDialog] = useState(false);
    const [selectedGroup, setSelectedGroup] = useState(null);
    const [newCity, setNewCity] = useState(null);

    useEffect(() => {
        fetchGroups();
        fetchCities();
    }, []);

    const fetchGroups = async () => {
        try {
            const token = localStorage.getItem('token'); // Get JWT token
            const response = await axios.get('https://dev.zapfly.pro/mostrar-nome-cidade-grupos', {
                headers: { 'x-access-token': token }
            });
            setGroups(response.data);
        } catch (error) {
            console.error('Erro ao buscar grupos:', error);
        }
    };

    const fetchCities = async () => {
        try {
            const token = localStorage.getItem('token'); // Get JWT token
            const response = await axios.get('https://dev.zapfly.pro/cities', {
                headers: { 'x-access-token': token }
            });
            setCities(response.data);
        } catch (error) {
            console.error('Erro ao buscar cidades:', error);
        }
    };

    const handleAddGroup = async () => {
        try {
            const token = localStorage.getItem('token'); // Get JWT token
            await axios.post('https://dev.zapfly.pro/add-group', {
                group_id: groupId,
                group_name: groupName,
                city_id: city.id
            }, {
                headers: { 'x-access-token': token }
            });
            setSnackbarMessage('Grupo adicionado com sucesso');
            setSnackbarSeverity('success');
            setOpenSnackbar(true);
            fetchGroups(); // Refresh the group list
        } catch (error) {
            console.error('Erro ao adicionar grupo:', error);
            setSnackbarMessage('Erro ao adicionar grupo');
            setSnackbarSeverity('error');
            setOpenSnackbar(true);
        }
    };

    const handleEditGroup = async () => {
        try {
            const token = localStorage.getItem('token'); // Get JWT token
            await axios.put(`https://dev.zapfly.pro/groups/${selectedGroup.id}`, {
                city_id: newCity.id
            }, {
                headers: { 'x-access-token': token }
            });
            setSnackbarMessage('Grupo atualizado com sucesso');
            setSnackbarSeverity('success');
            setOpenSnackbar(true);
            setOpenEditDialog(false);
            fetchGroups(); // Refresh the group list
        } catch (error) {
            console.error('Erro ao atualizar grupo:', error);
            setSnackbarMessage('Erro ao atualizar grupo');
            setSnackbarSeverity('error');
            setOpenSnackbar(true);
        }
    };

    const handleSnackbarClose = () => {
        setOpenSnackbar(false);
    };

    const columns = [
        { field: 'id', headerName: 'ID', width: 70 },
        { field: 'group_id', headerName: 'Group ID', width: 150 },
        { field: 'group_name', headerName: 'Nome do Grupo', width: 200 },
        { field: 'city_name', headerName: 'Cidade', width: 150 },
        {
            field: 'actions',
            headerName: 'Ações',
            width: 150,
            renderCell: (params) => (
                <Button
                    variant="contained"
                    sx={{
                        backgroundColor: logoGreen,
                        color: '#fff',
                        fontFamily,
                        '&:hover': {
                            backgroundColor: '#5aa437',
                        },
                        fontWeight: 'bold',
                    }}
                    size="small"
                    onClick={() => {
                        setSelectedGroup(params.row);
                        setNewCity(null);
                        setOpenEditDialog(true);
                    }}
                >
                    Editar
                </Button>
            ),
        },
    ];

    return (
        <Box display="flex" flexDirection="column" alignItems="center" mt={4} p={2} width="100%">
            <Paper elevation={3} sx={{ padding: 4, borderRadius: 2, backgroundColor: cardBackground, boxShadow: '0 3px 10px rgba(0,0,0,0.1)', width: '100%', maxWidth: 1200 }}>
                <Typography variant="h4" sx={{ fontFamily, color: textColor, fontWeight: 600, textAlign: 'center', mb: 3 }}>
                    Adicionar Grupo
                </Typography>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <TextField
                            label="ID do Grupo"
                            value={groupId}
                            onChange={(e) => setGroupId(e.target.value)}
                            margin="normal"
                            variant="outlined"
                            fullWidth
                            sx={{ fontFamily }}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            label="Nome do Grupo"
                            value={groupName}
                            onChange={(e) => setGroupName(e.target.value)}
                            margin="normal"
                            variant="outlined"
                            fullWidth
                            sx={{ fontFamily }}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <Autocomplete
                            options={cities}
                            getOptionLabel={(option) => option.city_name}
                            onChange={(event, newValue) => setCity(newValue)}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    variant="outlined"
                                    label="Cidade"
                                    placeholder="Selecione a cidade"
                                    margin="normal"
                                    fullWidth
                                    sx={{ fontFamily }}
                                />
                            )}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <Button 
                            variant="contained" 
                            sx={{
                                backgroundColor: logoGreen,
                                color: '#fff',
                                fontFamily,
                                '&:hover': {
                                    backgroundColor: '#5aa437',
                                },
                                fontWeight: 'bold',
                                minWidth: 160,
                                padding: '10px 20px'
                            }}
                            onClick={handleAddGroup}
                        >
                            Adicionar Grupo
                        </Button>
                    </Grid>
                </Grid>
                <Box sx={{ height: 400, width: '100%', mt: 4 }}>
                    <DataGrid
                        rows={groups}
                        columns={columns}
                        pageSize={5}
                        rowsPerPageOptions={[5]}
                        getRowId={(row) => row.id}
                        sx={{
                            '& .MuiDataGrid-columnHeaders': {
                                backgroundColor: logoGreen,
                                color: '#fff',
                                fontFamily,
                            },
                            '& .MuiDataGrid-cell': {
                                fontFamily,
                            }
                        }}
                    />
                </Box>
            </Paper>
            <Snackbar open={openSnackbar} autoHideDuration={6000} onClose={handleSnackbarClose}>
                <Alert onClose={handleSnackbarClose} severity={snackbarSeverity} sx={{ width: '100%', fontFamily }}>
                    {snackbarMessage}
                </Alert>
            </Snackbar>
            <Dialog open={openEditDialog} onClose={() => setOpenEditDialog(false)}>
                <DialogTitle sx={{ fontFamily }}>Editar Grupo</DialogTitle>
                <DialogContent>
                    <Autocomplete
                        options={cities}
                        getOptionLabel={(option) => option.city_name}
                        onChange={(event, newValue) => setNewCity(newValue)}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                variant="outlined"
                                label="Cidade"
                                placeholder="Selecione a cidade"
                                margin="normal"
                                fullWidth
                                sx={{ fontFamily }}
                            />
                        )}
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setOpenEditDialog(false)} sx={{ fontFamily }}>
                        Cancelar
                    </Button>
                    <Button onClick={handleEditGroup} sx={{ fontFamily }}>
                        Salvar
                    </Button>
                </DialogActions>
            </Dialog>
        </Box>
    );
};

export default AddGroups;
