import React, { useState, useEffect } from 'react';
import axios from 'axios';
import io from 'socket.io-client';
import dayjs from 'dayjs'; // Importa a biblioteca dayjs
import {
    Box, Typography, List, ListItem, ListItemText, Divider, Badge, Avatar, CssBaseline,
    IconButton, Paper, AppBar, Toolbar, InputAdornment, TextField, Snackbar, Alert, Button
} from '@mui/material';
import { Send as SendIcon, Search as SearchIcon, MoreVert as MoreVertIcon } from '@mui/icons-material';

const logoGreen = "#6cc24a";
const textColor = "#333333";
const fontFamily = "'Poppins', sans-serif";

const socket = io('https://dev.zapfly.pro');

const MessageViewer = () => {
    const [groups, setGroups] = useState([]);
    const [filteredGroups, setFilteredGroups] = useState([]);
    const [selectedGroupId, setSelectedGroupId] = useState(null);
    const [messages, setMessages] = useState([]);
    const [unreadCounts, setUnreadCounts] = useState({});
    const [newMessage, setNewMessage] = useState('');
    const [searchQuery, setSearchQuery] = useState('');
    const [groupName, setGroupName] = useState('');
    const [showAlert, setShowAlert] = useState(true);

    useEffect(() => {
        const fetchGroups = async () => {
            try {
                const token = localStorage.getItem('token');
                const response = await axios.get('https://dev.zapfly.pro/groups', {
                    headers: { 'x-access-token': token }
                });
                const sortedGroups = response.data.sort((a, b) => new Date(b.last_message_time) - new Date(a.last_message_time));
                setGroups(sortedGroups);
                setFilteredGroups(sortedGroups);
                const counts = sortedGroups.reduce((acc, group) => {
                    acc[group.group_id] = 0;
                    return acc;
                }, {});
                setUnreadCounts(counts);
            } catch (error) {
                console.error('Error fetching groups:', error);
            }
        };

        fetchGroups();
        const intervalId = setInterval(fetchGroups, 5000);

        return () => clearInterval(intervalId);
    }, []);

    useEffect(() => {
        if (selectedGroupId) {
            const fetchMessages = async () => {
                try {
                    const token = localStorage.getItem('token');
                    const response = await axios.get(`https://dev.zapfly.pro/group-messages/${selectedGroupId}`, {
                        headers: { 'x-access-token': token }
                    });
                    setMessages(response.data.sort((a, b) => b.timestamp - a.timestamp));
                    setUnreadCounts(prevCounts => ({ ...prevCounts, [selectedGroupId]: 0 }));

                    const selectedGroup = groups.find(group => group.group_id === selectedGroupId);
                    setGroupName(selectedGroup ? selectedGroup.group_name : '');
                } catch (error) {
                    console.error('Error fetching messages:', error);
                }
            };

            fetchMessages();
        }
    }, [selectedGroupId]);

    useEffect(() => {
        socket.on('newMessage', (data) => {
            if (data.groupId === selectedGroupId) {
                setMessages((prevMessages) => [data, ...prevMessages]);
            } else {
                setUnreadCounts((prevCounts) => ({
                    ...prevCounts,
                    [data.groupId]: prevCounts[data.groupId] + 1
                }));
            }
        });

        return () => {
            socket.off('newMessage');
        };
    }, [selectedGroupId]);

    const handleSearchChange = (event) => {
        const query = event.target.value.toLowerCase();
        setSearchQuery(query);
        setFilteredGroups(groups.filter(group => group.group_name.toLowerCase().includes(query)));
    };

    const handleSendMessage = () => {
        if (newMessage.trim() === '') return;

        try {
            const token = localStorage.getItem('token');
            axios.post(`https://dev.zapfly.pro/send-group-message-real-time`, {
                groupIds: JSON.stringify([selectedGroupId]),
                message: newMessage,
                timestamp: Math.floor(Date.now() / 1000)
            }, {
                headers: { 'x-access-token': token }
            });

            setNewMessage('');
        } catch (error) {
            console.error('Error sending message:', error);
        }
    };

    const formatDate = (timestamp) => {
        const date = dayjs(timestamp * 1000);
        return date.isToday() ? date.format('HH:mm') : date.format('DD/MM/YYYY HH:mm');
    };

    const handleCloseAlert = () => {
        setShowAlert(false);
    };

    return (
        <>
            <CssBaseline />
            {showAlert && (
                <Snackbar open={showAlert} autoHideDuration={null}>
                    <Alert severity="info" sx={{ width: '100%' }}>
                        Esse recurso está em fase de teste e constante atualização.
                        <Button color="inherit" size="small" onClick={handleCloseAlert}>
                            Fechar
                        </Button>
                    </Alert>
                </Snackbar>
            )}
            <Box display="flex" height="100vh">
                <Box width="25%" borderRight="1px solid #ccc" overflow="auto" sx={{ background: '#f0f2f5' }}>
                    <AppBar position="static" sx={{ background: logoGreen }}>
                        <Toolbar>
                            <Typography variant="h6" sx={{ flexGrow: 1, fontFamily }}>Grupos</Typography>
                            <IconButton color="inherit">
                                <MoreVertIcon />
                            </IconButton>
                        </Toolbar>
                    </AppBar>
                    <Divider />
                    <Box p={2}>
                        <TextField
                            variant="outlined"
                            fullWidth
                            placeholder="Pesquisar grupos..."
                            value={searchQuery}
                            onChange={handleSearchChange}
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <SearchIcon />
                                    </InputAdornment>
                                ),
                                sx: { backgroundColor: '#fff', borderRadius: '20px', fontFamily }
                            }}
                        />
                    </Box>
                    <Divider />
                    <List>
                        {filteredGroups.map(group => (
                            <ListItem
                                button
                                key={group.group_id}
                                onClick={() => setSelectedGroupId(group.group_id)}
                                selected={group.group_id === selectedGroupId}
                                sx={{ '&.Mui-selected': { backgroundColor: '#dcf8c6' }, '&:hover': { backgroundColor: '#e9ecef' } }}
                            >
                                <Avatar alt={group.group_name} src={group.profile_picture_url || ''} />
                                <ListItemText primary={
                                    <Box display="flex" justifyContent="space-between" alignItems="center">
                                        <Typography sx={{ fontFamily }}>{group.group_name}</Typography>
                                        {unreadCounts[group.group_id] > 0 && (
                                            <Badge badgeContent={unreadCounts[group.group_id]} color="secondary" />
                                        )}
                                    </Box>
                                } />
                            </ListItem>
                        ))}
                    </List>
                </Box>
                <Box width="75%" display="flex" flexDirection="column">
                    <AppBar position="static" sx={{ background: logoGreen }}>
                        <Toolbar>
                            <Typography variant="h6" sx={{ flexGrow: 1, fontFamily }}>Conversas - {groupName}</Typography>
                            <IconButton color="inherit">
                                <MoreVertIcon />
                            </IconButton>
                        </Toolbar>
                    </AppBar>
                    <Box p={2} flexGrow={1} overflow="auto" sx={{ backgroundColor: '#e5ddd5' }}>
                        <List>
                            {messages.map((msg, index) => (
                                <ListItem key={index} alignItems="flex-start" sx={{ marginBottom: '10px' }}>
                                    <Avatar alt="Foto de Perfil" src={msg.profile_picture_url || ''} />
                                    <Box sx={{ marginLeft: '10px', backgroundColor: '#fff', padding: '10px', borderRadius: '10px', maxWidth: '70%' }}>
                                        <ListItemText
                                            primary={
                                                <Typography sx={{ fontFamily }}>
                                                    {msg.message}
                                                    {msg.image_url && <img src={msg.image_url} alt="" width="100" style={{ marginTop: '10px', borderRadius: '10px' }} />}
                                                    {msg.video_url && (
                                                        <video width="100" controls style={{ marginTop: '10px', borderRadius: '10px' }}>
                                                            <source src={msg.video_url} type="video/mp4" />
                                                            Your browser does not support the video tag.
                                                        </video>
                                                    )}
                                                    {msg.document_url && (
                                                        <a href={msg.document_url} target="_blank" rel="noopener noreferrer" style={{ marginTop: '10px', display: 'block', color: logoGreen }}>
                                                            Ver Documento
                                                        </a>
                                                    )}
                                                    {msg.audio_url && (
                                                        <audio controls style={{ marginTop: '10px' }}>
                                                            <source src={msg.audio_url} type="audio/mpeg" />
                                                            Your browser does not support the audio tag.
                                                        </audio>
                                                    )}
                                                </Typography>
                                            }
                                            secondary={
                                                <Typography variant="body2" color="textSecondary" sx={{ fontFamily }}>
                                                    {msg.sender_id} - {formatDate(msg.timestamp)}
                                                </Typography>
                                            }
                                        />
                                    </Box>
                                </ListItem>
                            ))}
                        </List>
                    </Box>
                    <Paper elevation={3} sx={{ display: 'flex', alignItems: 'center', padding: 1, backgroundColor: '#f0f2f5' }}>
                        <TextField
                            fullWidth
                            variant="outlined"
                            placeholder="Digite sua mensagem..."
                            value={newMessage}
                            onChange={(e) => setNewMessage(e.target.value)}
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position="end">
                                        <IconButton color="primary" onClick={handleSendMessage}>
                                            <SendIcon />
                                        </IconButton>
                                    </InputAdornment>
                                ),
                                sx: { backgroundColor: '#fff', borderRadius: '20px', fontFamily }
                            }}
                        />
                    </Paper>
                </Box>
            </Box>
        </>
    );
};

export default MessageViewer;
