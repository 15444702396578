import React from 'react';
import { AppBar, Toolbar, IconButton, Typography, Menu, MenuItem } from '@mui/material';
import AccountCircle from '@mui/icons-material/AccountCircle';
import { useNavigate, useLocation } from 'react-router-dom';
import jwtDecode from 'jwt-decode';

const logoGreen = "#6cc24a"; // Use a cor dos botões do sistema

const Header = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [anchorEl, setAnchorEl] = React.useState(null);
  
  const token = localStorage.getItem('token');
  const user = token ? jwtDecode(token) : {};

  const handleMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleLogout = () => {
    localStorage.removeItem('token');
    window.location.href = '/login';  // Força o recarregamento da página
  };
  

  // Definir o nome da página atual
  const getPageName = () => {
    switch (location.pathname) {
      case '/dashboard':
        return 'Dashboard';
      case '/send-message':
        return 'Enviar Mensagem';
      case '/messages':
        return 'Ver Mensagens';
      case '/history':
        return 'Histórico';
      case '/qr-reader':
        return 'Leitura do QR Code';
      case '/register-user':
        return 'Registrar Usuário';
      case '/add-cities':
        return 'Adicionar Cidade';
      case '/add-groups':
        return 'Adicionar Grupo';
      default:
        return 'Fazer login';
    }
  };

  return (
    <AppBar position="static" sx={{ backgroundColor: logoGreen }}>
      <Toolbar>
        <Typography variant="h6" sx={{ flexGrow: 1 }}>
          {getPageName()}
        </Typography>
        {user && (
          <div>
            <IconButton
              size="large"
              edge="end"
              aria-label="account of current user"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={handleMenu}
              color="inherit"
            >
              <AccountCircle />
            </IconButton>
            <Menu
              id="menu-appbar"
              anchorEl={anchorEl}
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              keepMounted
              transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              open={Boolean(anchorEl)}
              onClose={handleClose}
            >
              <MenuItem onClick={() => navigate('/meu-perfil')}>Meu Perfil</MenuItem>
              <MenuItem onClick={handleLogout}>Sair</MenuItem>
            </Menu>
          </div>
        )}
      </Toolbar>
    </AppBar>
  );
};

export default Header;
