import React, { useState, useEffect } from 'react';
import { Box, TextField, Button, Typography, Paper, Snackbar, Alert } from '@mui/material';
import axios from 'axios';
import jwtDecode from 'jwt-decode';

const MeuPerfil = () => {
  const [user, setUser] = useState({});
  const [email, setEmail] = useState('');
  const [currentPassword, setCurrentPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [planName, setPlanName] = useState('');
  const [planDueDate, setPlanDueDate] = useState('');
  const [paymentStatus, setPaymentStatus] = useState('');
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [snackbarSeverity, setSnackbarSeverity] = useState('success');
  const [openSnackbar, setOpenSnackbar] = useState(false);

  useEffect(() => {
    const token = localStorage.getItem('token');
    if (token) {
      axios.get('https://dev.zapfly.pro/me', {
        headers: {
          'Authorization': `Bearer ${token}`
        }
      })
      .then(response => {
        const { email, plan_name, plan_due_date, payment_status } = response.data;
        setEmail(email);
        setPlanName(plan_name);
        setPlanDueDate(plan_due_date);
        setPaymentStatus(payment_status);
      })
      .catch(error => {
        console.error('Error fetching user profile:', error);
      });
    }
  }, []);

  const handleUpdatePassword = async () => {
    if (newPassword !== confirmPassword) {
      setSnackbarMessage('As senhas não coincidem.');
      setSnackbarSeverity('error');
      setOpenSnackbar(true);
      return;
    }

    try {
      const token = localStorage.getItem('token');
      await axios.put('https://dev.zapfly.pro/me/update-password', {
        currentPassword,
        newPassword,
      }, {
        headers: {
          'Authorization': `Bearer ${token}`
        }
      });

      setSnackbarMessage('Senha atualizada com sucesso!');
      setSnackbarSeverity('success');
      setOpenSnackbar(true);
    } catch (error) {
      setSnackbarMessage('Erro ao atualizar a senha.');
      setSnackbarSeverity('error');
      setOpenSnackbar(true);
    }
  };

  return (
    <Box display="flex" justifyContent="center" alignItems="center" height="100vh" p={2}>
      <Paper elevation={3} sx={{ padding: 4, maxWidth: 1200, width: '100%' }}>
        <Typography variant="h6" sx={{ marginBottom: 2 }}>
          Meu Perfil
        </Typography>
        <Box component="form" noValidate autoComplete="off">
          <TextField
            fullWidth
            margin="normal"
            label="E-mail"
            variant="outlined"
            value={email}
            disabled
          />
          <TextField
            fullWidth
            margin="normal"
            label="Senha Atual"
            variant="outlined"
            type="password"
            value={currentPassword}
            onChange={(e) => setCurrentPassword(e.target.value)}
          />
          <TextField
            fullWidth
            margin="normal"
            label="Nova Senha"
            variant="outlined"
            type="password"
            value={newPassword}
            onChange={(e) => setNewPassword(e.target.value)}
          />
          <TextField
            fullWidth
            margin="normal"
            label="Confirmar Nova Senha"
            variant="outlined"
            type="password"
            value={confirmPassword}
            onChange={(e) => setConfirmPassword(e.target.value)}
          />
          <TextField
            fullWidth
            margin="normal"
            label="Plano"
            variant="outlined"
            value={planName || 'Sem plano'}
            disabled
          />
          <TextField
            fullWidth
            margin="normal"
            label="Data de Vencimento do Plano"
            variant="outlined"
            value={planDueDate || 'Indisponível'}
            disabled
          />
          <TextField
            fullWidth
            margin="normal"
            label="Status do Pagamento"
            variant="outlined"
            value={paymentStatus || 'Indisponível'}
            disabled
          />
          <Button
            fullWidth
            variant="contained"
            sx={{ marginTop: 2, backgroundColor: '#6cc24a', '&:hover': { backgroundColor: '#5cb041' } }}
            onClick={handleUpdatePassword}
          >
            Atualizar Senha
          </Button>
        </Box>
      </Paper>
      <Snackbar open={openSnackbar} autoHideDuration={6000} onClose={() => setOpenSnackbar(false)}>
        <Alert onClose={() => setOpenSnackbar(false)} severity={snackbarSeverity} sx={{ width: '100%' }}>
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </Box>
  );
};

export default MeuPerfil;
