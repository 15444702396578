import React, { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { Drawer, List, ListItem, ListItemText, ListItemIcon, Toolbar, Box, Typography, IconButton, Tooltip } from '@mui/material';
import DashboardIcon from '@mui/icons-material/Dashboard';
import SendIcon from '@mui/icons-material/Send';
import MessageIcon from '@mui/icons-material/Message';
import HistoryIcon from '@mui/icons-material/History';
import QrCodeScannerIcon from '@mui/icons-material/QrCodeScanner';
import PersonAddIcon from '@mui/icons-material/PersonAdd';
import LocationCityIcon from '@mui/icons-material/LocationCity';
import GroupAddIcon from '@mui/icons-material/GroupAdd';
import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import MenuIcon from '@mui/icons-material/Menu';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import jwtDecode from 'jwt-decode';

const drawerWidth = 240;
const drawerWidthCollapsed = 60;
const logoGreen = "#6cc24a"; // Color from your logo
const backgroundColor = "#f5f5f5"; // Light background to contrast with icons
const textColor = "#333333"; // Dark text color

const Sidebar = () => {
  const navigate = useNavigate();
  const token = localStorage.getItem('token');
  const userId = token ? jwtDecode(token).id : null;

  const handleLogout = () => {
    localStorage.removeItem('token');
    window.location.href = '/login';  // Força o recarregamento da página
  };
  

  const [currentTime, setCurrentTime] = useState(new Date().toLocaleTimeString());
  const [isCollapsed, setIsCollapsed] = useState(false);

  useEffect(() => {
    const timer = setInterval(() => {
      setCurrentTime(new Date().toLocaleTimeString());
    }, 1000);

    return () => clearInterval(timer);
  }, []);

  const toggleSidebar = () => {
    setIsCollapsed(!isCollapsed);
  };

  return (
    <Drawer
      variant="permanent"
      sx={{
        width: isCollapsed ? drawerWidthCollapsed : drawerWidth,
        flexShrink: 0,
        [`& .MuiDrawer-paper`]: {
          width: isCollapsed ? drawerWidthCollapsed : drawerWidth,
          boxSizing: 'border-box',
          backgroundColor: backgroundColor,
          borderRight: `1px solid ${logoGreen}`,
          boxShadow: '4px 0 10px rgba(0,0,0,0.1)',
          transition: 'all 0.3s ease',
          '&:hover': {
            boxShadow: '8px 0 15px rgba(0,0,0,0.2)',
          },
        },
      }}
    >
      <Toolbar
        sx={{
          display: 'flex',
          justifyContent: isCollapsed ? 'center' : 'space-between',
          alignItems: 'center',
          padding: '0 8px',
        }}
      >
        {!isCollapsed && (
          <img
            src="https://zapfly.pro/img/logo.png"
            alt="Logo"
            style={{ width: '50%', marginBottom: '10px' }}
          />
        )}
        <IconButton onClick={toggleSidebar} sx={{ color: logoGreen }}>
          <MenuIcon />
        </IconButton>
      </Toolbar>
      <List>
        <Tooltip title="Dashboard" placement="right" disableHoverListener={!isCollapsed}>
          <ListItem button component={Link} to="/dashboard">
            <ListItemIcon>
              <DashboardIcon sx={{ color: logoGreen }} />
            </ListItemIcon>
            {!isCollapsed && <ListItemText primary="Dashboard" sx={{ color: textColor }} />}
          </ListItem>
        </Tooltip>
        <Tooltip title="Enviar Mensagem" placement="right" disableHoverListener={!isCollapsed}>
          <ListItem button component={Link} to="/send-message">
            <ListItemIcon>
              <SendIcon sx={{ color: logoGreen }} />
            </ListItemIcon>
            {!isCollapsed && <ListItemText primary="Enviar Mensagem" sx={{ color: textColor }} />}
          </ListItem>
        </Tooltip>
        <Tooltip title="Ver Mensagens" placement="right" disableHoverListener={!isCollapsed}>
          <ListItem button component={Link} to="/messages">
            <ListItemIcon>
              <MessageIcon sx={{ color: logoGreen }} />
            </ListItemIcon>
            {!isCollapsed && <ListItemText primary="Ver Mensagens" sx={{ color: textColor }} />}
          </ListItem>
        </Tooltip>
        <Tooltip title="Histórico" placement="right" disableHoverListener={!isCollapsed}>
          <ListItem button component={Link} to="/history">
            <ListItemIcon>
              <HistoryIcon sx={{ color: logoGreen }} />
            </ListItemIcon>
            {!isCollapsed && <ListItemText primary="Histórico" sx={{ color: textColor }} />}
          </ListItem>
        </Tooltip>
        {userId === 1 && (
          <>
            <Tooltip title="Leitura do QR Code" placement="right" disableHoverListener={!isCollapsed}>
              <ListItem button component={Link} to="/qr-reader">
                <ListItemIcon>
                  <QrCodeScannerIcon sx={{ color: logoGreen }} />
                </ListItemIcon>
                {!isCollapsed && <ListItemText primary="Leitura do QR Code" sx={{ color: textColor }} />}
              </ListItem>
            </Tooltip>
            <Tooltip title="Registrar Usuário" placement="right" disableHoverListener={!isCollapsed}>
              <ListItem button component={Link} to="/register-user">
                <ListItemIcon>
                  <PersonAddIcon sx={{ color: logoGreen }} />
                </ListItemIcon>
                {!isCollapsed && <ListItemText primary="Registrar Usuário" sx={{ color: textColor }} />}
              </ListItem>
            </Tooltip>
            <Tooltip title="Adicionar Cidade" placement="right" disableHoverListener={!isCollapsed}>
              <ListItem button component={Link} to="/add-cities">
                <ListItemIcon>
                  <LocationCityIcon sx={{ color: logoGreen }} />
                </ListItemIcon>
                {!isCollapsed && <ListItemText primary="Adicionar Cidade" sx={{ color: textColor }} />}
              </ListItem>
            </Tooltip>
            <Tooltip title="Adicionar Grupo" placement="right" disableHoverListener={!isCollapsed}>
              <ListItem button component={Link} to="/add-groups">
                <ListItemIcon>
                  <GroupAddIcon sx={{ color: logoGreen }} />
                </ListItemIcon>
                {!isCollapsed && <ListItemText primary="Adicionar Grupo" sx={{ color: textColor }} />}
              </ListItem>
            </Tooltip>
          </>
        )}
        <Tooltip title="Sair" placement="right" disableHoverListener={!isCollapsed}>
          <ListItem button onClick={handleLogout}>
            <ListItemIcon>
              <ExitToAppIcon sx={{ color: logoGreen }} />
            </ListItemIcon>
            {!isCollapsed && <ListItemText primary="Sair" sx={{ color: textColor }} />}
          </ListItem>
        </Tooltip>
      </List>
      {!isCollapsed && (
        <Box sx={{ textAlign: 'center', mt: 'auto', mb: 2 }}>
          <AccessTimeIcon sx={{ color: logoGreen }} />
          <Typography variant="body2" sx={{ color: textColor }}>
            {currentTime}
          </Typography>
        </Box>
      )}
    </Drawer>
  );
};

export default Sidebar;
