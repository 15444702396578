import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Box, Button, Typography, CircularProgress, Snackbar, Alert } from '@mui/material';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';

const QRCodeReader = () => {
    const [qrCode, setQrCode] = useState('');
    const [connected, setConnected] = useState(false);
    const [lastUpdated, setLastUpdated] = useState(null);
    const [loading, setLoading] = useState(true);
    const [openSnackbar, setOpenSnackbar] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState('');

    useEffect(() => {
        const fetchConnectionStatus = async () => {
            try {
                const token = localStorage.getItem('token'); // Get JWT token
                const response = await axios.get('https://dev.zapfly.pro/connection-status', {
                    headers: { 'x-access-token': token }
                });
                setConnected(response.data.connected);
                setLastUpdated(response.data.lastUpdated);
                setLoading(false);
            } catch (error) {
                console.error('Error fetching connection status:', error);
                setLoading(false);
            }
        };

        fetchConnectionStatus();
    }, []);

    useEffect(() => {
        if (!connected) {
            const fetchQRCode = async () => {
                try {
                    const token = localStorage.getItem('token'); // Get JWT token
                    const response = await axios.get('https://dev.zapfly.pro/qr', {
                        headers: { 'x-access-token': token }
                    });
                    setQrCode(response.data);
                } catch (error) {
                    console.error('Error fetching QR code:', error);
                }
            };

            fetchQRCode();
        }
    }, [connected]);

    const handleSnackbarClose = () => {
        setOpenSnackbar(false);
    };

    const handleReconnect = async () => {
        try {
            const token = localStorage.getItem('token'); // Get JWT token
            await axios.post('https://dev.zapfly.pro/reconnect', {}, {
                headers: { 'x-access-token': token }
            });
            setSnackbarMessage('Reconnecting...');
            setOpenSnackbar(true);
            setConnected(false); // Trigger QR code fetch again
        } catch (error) {
            console.error('Error reconnecting:', error);
            setSnackbarMessage('Error reconnecting');
            setOpenSnackbar(true);
        }
    };

    if (loading) {
        return <CircularProgress />;
    }

    return (
        <Box display="flex" flexDirection="column" alignItems="center" justifyContent="center" mt={4} p={2} width="100%">
            <Typography variant="h4" gutterBottom>Leitura do QR Code</Typography>
            {connected ? (
                <Box display="flex" flexDirection="column" alignItems="center" justifyContent="center">
                    <CheckCircleIcon color="success" style={{ fontSize: 100 }} />
                    <Typography variant="h6" color="success.main" sx={{ mt: 2 }}>Conectado com sucesso</Typography>
                </Box>
            ) : (
                <>
                    <Typography variant="h6" color="error.main" sx={{ mb: 2 }}>Não conectado</Typography>
                    <img src={`data:image/svg+xml;base64,${btoa(qrCode)}`} alt="QR Code" width="400" height="400" style={{ border: '1px solid #ccc', borderRadius: '10px' }} />
                    <Button variant="contained" color="primary" onClick={handleReconnect} sx={{ mt: 2 }}>
                        Reconnect
                    </Button>
                </>
            )}
            {lastUpdated && (
                <Typography variant="body1" sx={{ mt: 2 }}>Última atualização: {new Date(lastUpdated).toLocaleString()}</Typography>
            )}
            <Snackbar open={openSnackbar} autoHideDuration={6000} onClose={handleSnackbarClose}>
                <Alert onClose={handleSnackbarClose} severity={connected ? 'success' : 'error'} sx={{ width: '100%' }}>
                    {snackbarMessage}
                </Alert>
            </Snackbar>
        </Box>
    );
};

export default QRCodeReader;
