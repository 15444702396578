import React, { Component } from "react";
import axios from 'axios';
import { Box, TextField, Button, Snackbar, Alert, Paper, Autocomplete, Typography } from '@mui/material';
import { Navigate, Link as RouterLink } from 'react-router-dom';

export default class AutoCadastro extends Component {
  state = {
    email: "",
    password: "",
    cities: [],
    selectedCity: null,
    openSnackbar: false,
    snackbarMessage: '',
    snackbarSeverity: 'success',
    redirectToLogin: false
  };

  componentDidMount() {
    this.fetchCities();
  }

  fetchCities = async () => {
    try {
      const response = await axios.get('https://dev.zapfly.pro/clientes-novo-cadastro-cidades');
      this.setState({ cities: response.data });
    } catch (error) {
      console.error('Erro ao buscar cidades:', error);
    }
  };

  handleChange = (e) => {
    this.setState({ [e.target.id]: e.target.value });
  };

  handleCityChange = (event, value) => {
    this.setState({ selectedCity: value });
  };

handleSubmit = async (e) => {
  e.preventDefault();
  const { email, password, selectedCity } = this.state;
  try {
    const response = await axios.post('https://dev.zapfly.pro/auto-cadastro', { 
      email, 
      password, 
      city_id: selectedCity.id 
    });

    const { redirectUrl } = response.data;

    this.setState({
      snackbarSeverity: 'success',
      snackbarMessage: 'Cadastro realizado com sucesso! Redirecionando para o pagamento...',
      openSnackbar: true,
      email: '',
      password: '',
      selectedCity: null
    });

    // Redireciona para o checkout
    setTimeout(() => {
      window.location.href = redirectUrl;
    }, 2000); // Aguarda 2 segundos para exibir a mensagem
  } catch (error) {
    this.setState({
      snackbarSeverity: 'error',
      snackbarMessage: 'Erro ao realizar cadastro: ' + (error.response ? error.response.data.message : 'Server error'),
      openSnackbar: true
    });
  }
};


  handleSnackbarClose = () => {
    this.setState({ openSnackbar: false });
  };

  render() {
    const { email, password, cities, selectedCity, openSnackbar, snackbarMessage, snackbarSeverity, redirectToLogin } = this.state;

    if (redirectToLogin) {
      return <Navigate to="/login" />;
    }

    return (
      <Box 
        display="flex" 
        justifyContent="center" 
        alignItems="center" 
        height="100vh"
        sx={{
          backgroundColor: '#f4f7fa',  // Soft background color for contrast
          padding: 2,  // Add padding to ensure content is well-spaced
        }}
      >
        <Paper 
          elevation={8} 
          sx={{ 
            padding: 4, 
            maxWidth: 420, 
            width: '100%', 
            boxShadow: '0px 8px 30px rgba(0, 0, 0, 0.15)',  // Enhanced shadow for depth
            borderRadius: 4,  // Smooth corners
            backgroundColor: '#ffffff',  // Keep the paper background white
            transition: 'all 0.3s ease-in-out',  // Smooth hover transition
            '&:hover': {
              transform: 'scale(1.02)',  // Slight scale-up on hover for interactivity
            }
          }}
        >
          <Box display="flex" justifyContent="center" mb={3}>
            <img src="https://zapfly.pro/img/logo.png" alt="Logo" style={{ height: 60 }} />
          </Box>
          
          <form onSubmit={this.handleSubmit}>
            <Box mb={3}>
              <TextField
                fullWidth
                variant="outlined"
                label="E-mail"
                id="email"
                value={email}
                onChange={this.handleChange}
                sx={{ '& .MuiOutlinedInput-root': { borderRadius: 2 } }}  // Slightly rounded input fields
              />
            </Box>
            <Box mb={3}>
              <TextField
                fullWidth
                variant="outlined"
                label="Senha"
                type="password"
                id="password"
                value={password}
                onChange={this.handleChange}
                sx={{ '& .MuiOutlinedInput-root': { borderRadius: 2 } }}  // Slightly rounded input fields
              />
            </Box>
            <Box mb={3}>
              <Autocomplete
                options={cities}
                getOptionLabel={(option) => option.city_name}
                value={selectedCity}
                onChange={this.handleCityChange}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant="outlined"
                    label="Cidade"
                    placeholder="Selecione a cidade"
                    margin="normal"
                    fullWidth
                    sx={{ '& .MuiOutlinedInput-root': { borderRadius: 2 } }}  // Slightly rounded input fields
                  />
                )}
              />
            </Box>
            <Box mb={3}>
              <Typography variant="body2" color="textSecondary">
                Você receberá um e-mail automático com seus dados de acesso. Por favor, informe o e-mail correto no momento do cadastro.
              </Typography>
            </Box>
            <Box mb={2}>
              <Button 
                type="submit" 
                variant="contained" 
                fullWidth
                sx={{ 
                  padding: 1.5, 
                  backgroundColor: '#6cc24a', 
                  color: '#fff', 
                  fontWeight: 'bold', 
                  '&:hover': { backgroundColor: '#5cb041' } 
                }}
              >
                Registrar
              </Button>
            </Box>
          </form>
          <Box mb={2}>
            <Button
              variant="outlined"
              fullWidth
              sx={{
                padding: 1.5,
                color: '#6cc24a',
                borderColor: '#6cc24a',
                fontWeight: 'bold',
                '&:hover': { 
                  backgroundColor: '#f4f7fa', 
                  borderColor: '#5cb041',
                  color: '#5cb041'
                } 
              }}
              component={RouterLink} // Utilizando RouterLink para navegação
              to="/login" // Redireciona para a página de login
            >
              Voltar
            </Button>
          </Box>
          <Snackbar open={openSnackbar} autoHideDuration={6000} onClose={this.handleSnackbarClose}>
            <Alert onClose={this.handleSnackbarClose} severity={snackbarSeverity} sx={{ width: '100%' }}>
              {snackbarMessage}
            </Alert>
          </Snackbar>
        </Paper>
      </Box>
    );
  }
}
