import React, { useState, useEffect } from 'react';
import axios from 'axios';
import {
    Box, Typography, Paper, CircularProgress, IconButton, Tooltip,
    Dialog, DialogTitle, DialogContent, DialogActions, Button, Table, TableBody, TableCell,
    TableContainer, TableHead, TableRow, Pagination, Avatar
} from '@mui/material';
import VisibilityIcon from '@mui/icons-material/Visibility';
import ErrorIcon from '@mui/icons-material/Error';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import HourglassEmptyIcon from '@mui/icons-material/HourglassEmpty';

const logoGreen = "#6cc24a"; // Color from your logo
const cardBackground = "#ffffff"; // Card background color
const textColor = "#333333"; // Dark text color
const fontFamily = "'Poppins', sans-serif"; // Poppins font

const History = () => {
    const [history, setHistory] = useState([]);
    const [loading, setLoading] = useState(true);
    const [openModal, setOpenModal] = useState(false);
    const [selectedMessage, setSelectedMessage] = useState(null);
    const [page, setPage] = useState(1);
    const rowsPerPage = 5;

    useEffect(() => {
        const fetchHistory = async () => {
            try {
                const token = localStorage.getItem('token');
                const response = await axios.get('https://dev.zapfly.pro/history', {
                    headers: { 'x-access-token': token },
                });
                const sortedHistory = response.data.sort((a, b) => new Date(b.created_at) - new Date(a.created_at));
                setHistory(sortedHistory);
                setLoading(false);
            } catch (error) {
                console.error('Erro ao buscar histórico:', error);
                setLoading(false);
            }
        };

        fetchHistory();

        const intervalId = setInterval(fetchHistory, 4000); // Atualiza a cada 4 segundos

        return () => clearInterval(intervalId); // Limpa o intervalo ao desmontar o componente
    }, []);

    const handleView = async (id) => {
        try {
            const token = localStorage.getItem('token');
            const response = await axios.get(`https://dev.zapfly.pro/message/${id}`, {
                headers: { 'x-access-token': token },
            });
            setSelectedMessage(response.data);
            setOpenModal(true);
        } catch (error) {
            console.error('Erro ao buscar detalhes da mensagem:', error);
        }
    };

    const handleCloseModal = () => {
        setOpenModal(false);
        setSelectedMessage(null);
    };

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const renderStatusIcon = (status) => {
        switch (status) {
            case 'sent':
                return <CheckCircleIcon style={{ color: 'green' }} />;
            case 'pending':
                return <HourglassEmptyIcon style={{ color: 'orange' }} />;
            case 'failed':
                return <ErrorIcon style={{ color: 'red' }} />;
            default:
                return null;
        }
    };

    const translateStatus = (status) => {
        switch (status) {
            case 'sent':
                return 'Enviado';
            case 'pending':
                return 'Pendente';
            case 'failed':
                return 'Erro';
            default:
                return status;
        }
    };

    return (
        <Box sx={{ padding: 3, fontFamily }}>
            <Typography variant="h5" gutterBottom sx={{ fontFamily, color: textColor, fontWeight: 600 }}>
                Histórico de Envio
            </Typography>
            <Paper sx={{ boxShadow: 4, padding: 2, backgroundColor: cardBackground }}>
                {loading ? (
                    <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
                        <CircularProgress />
                    </Box>
                ) : (
                    <>
                        <TableContainer>
                            <Table>
                                <TableHead>
                                    <TableRow sx={{ backgroundColor: logoGreen }}>
                                        <TableCell sx={{ color: '#ffffff', fontWeight: 600 }}>ID</TableCell>
                                        <TableCell sx={{ color: '#ffffff', fontWeight: 600 }}>Nome do Grupo</TableCell>
                                        <TableCell sx={{ color: '#ffffff', fontWeight: 600 }}>Status</TableCell>
                                        <TableCell sx={{ color: '#ffffff', fontWeight: 600 }}>Data e Hora</TableCell>
                                        <TableCell sx={{ color: '#ffffff', fontWeight: 600 }}>Ações</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {history
                                        .slice((page - 1) * rowsPerPage, page * rowsPerPage)
                                        .map((row) => (
                                            <TableRow key={row.id}>
                                                <TableCell>{row.id}</TableCell>
                                                <TableCell>{row.group_name}</TableCell>
                                                <TableCell>
                                                    <Tooltip title={translateStatus(row.status)}>
                                                        <Avatar>{renderStatusIcon(row.status)}</Avatar>
                                                    </Tooltip>
                                                </TableCell>
                                                <TableCell>
                                                    {row.created_at
                                                        ? new Date(row.created_at).toLocaleString()
                                                        : 'N/A'}
                                                </TableCell>
                                                <TableCell>
                                                    <Tooltip title="Visualizar">
                                                        <IconButton color="primary" onClick={() => handleView(row.id)}>
                                                            <VisibilityIcon />
                                                        </IconButton>
                                                    </Tooltip>
                                                </TableCell>
                                            </TableRow>
                                        ))}
                                </TableBody>
                            </Table>
                        </TableContainer>
                        <Pagination
                            count={Math.ceil(history.length / rowsPerPage)}
                            page={page}
                            onChange={handleChangePage}
                            sx={{ mt: 2, display: 'flex', justifyContent: 'center' }}
                        />
                    </>
                )}
            </Paper>

            <Dialog open={openModal} onClose={handleCloseModal}>
                <DialogTitle sx={{ fontFamily }}>Detalhes da Mensagem</DialogTitle>
                <DialogContent>
                    {selectedMessage && (
                        <Box>
                            <Typography variant="body1" sx={{ fontFamily }}><strong>Nome do Grupo:</strong> {selectedMessage.group_name}</Typography>
                            <Typography variant="body1" sx={{ fontFamily }}><strong>Mensagem:</strong> {selectedMessage.caption}</Typography>
                            <Typography variant="body1" sx={{ fontFamily }}><strong>Status:</strong> {translateStatus(selectedMessage.status)}</Typography>
                            <Typography variant="body1" sx={{ fontFamily }}><strong>Erro:</strong> {selectedMessage.error || 'Nenhum'}</Typography>
                            <Typography variant="body1" sx={{ fontFamily }}><strong>Data e Hora:</strong> {new Date(selectedMessage.created_at).toLocaleString()}</Typography>
                            {selectedMessage.image_url && (
                                <Box mt={2} textAlign="center">
                                    <img src={selectedMessage.image_url} alt="Imagem da Mensagem" style={{ maxWidth: '100%' }} />
                                </Box>
                            )}
                        </Box>
                    )}
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCloseModal} color="primary" sx={{ fontFamily }}>
                        Fechar
                    </Button>
                </DialogActions>
            </Dialog>
        </Box>
    );
};

export default History;
