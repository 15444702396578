import React, { Component } from "react";
import axios from 'axios';
import { Box, TextField, Button, Snackbar, Alert, Paper, Link, Typography } from '@mui/material';
import { Link as RouterLink } from 'react-router-dom';
import "./styles.css";

export default class SignIn extends Component {
  state = {
    email: "",
    password: "",
    openSnackbar: false,
    snackbarMessage: '',
    snackbarSeverity: 'success'
  };

  handleChange = (e) => {
    this.setState({ [e.target.id]: e.target.value });
  };

  handleSubmit = async (e) => {
    e.preventDefault();
    const { email, password } = this.state;
    try {
      const response = await axios.post('https://dev.zapfly.pro/login', { email, password });
      localStorage.setItem('token', response.data.token);
      this.setState({
        snackbarSeverity: 'success',
        snackbarMessage: 'Login realizado com sucesso!',
        openSnackbar: true
      });
      setTimeout(() => {
        window.location.href = '/dashboard'; // Redireciona após a mensagem de sucesso
      }, 2000); // Espera 2 segundos antes de redirecionar
    } catch (error) {
      this.setState({
        snackbarSeverity: 'error',
        snackbarMessage: 'Falha no login: ' + (error.response ? error.response.data.message : 'Erro no servidor'),
        openSnackbar: true
      });
    }
  };

  handleSnackbarClose = () => {
    this.setState({ openSnackbar: false });
  };

  render() {
    const { email, password, openSnackbar, snackbarMessage, snackbarSeverity } = this.state;
    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        height="100vh"
        sx={{
          backgroundColor: '#f0f2f5',
          margin: 0,
          padding: 0,
          width: '100vw',
          height: '100vh',
          overflow: 'hidden'
        }}
      >
        <Paper 
          elevation={6} 
          sx={{ 
            padding: 4, 
            maxWidth: 400, 
            width: '100%', 
            boxShadow: '0px 8px 30px rgba(0, 0, 0, 0.2)', 
            borderRadius: 4,
            backgroundColor: '#ffffff',
          }}
        >
          <Box display="flex" justifyContent="center" mb={2}>
            <img src="https://zapfly.pro/img/logo.png" alt="Logo" style={{ height: 60 }} />
          </Box>
          
          <form onSubmit={this.handleSubmit}>
            <Box mb={2}>
              <TextField
                fullWidth
                variant="outlined"
                label="E-mail"
                id="email"
                value={email}
                onChange={this.handleChange}
                InputLabelProps={{
                  style: { color: '#6cc24a' } // Cor do label
                }}
                sx={{
                  '& .MuiOutlinedInput-root': {
                    '& fieldset': {
                      borderColor: '#6cc24a', // Cor da borda
                    },
                    '&:hover fieldset': {
                      borderColor: '#5cb041', // Cor da borda ao hover
                    },
                    '&.Mui-focused fieldset': {
                      borderColor: '#6cc24a', // Cor da borda ao focar
                    },
                  },
                }}
              />
            </Box>
            <Box mb={2}>
              <TextField
                fullWidth
                variant="outlined"
                label="Senha"
                type="password"
                id="password"
                value={password}
                onChange={this.handleChange}
                InputLabelProps={{
                  style: { color: '#6cc24a' } // Cor do label
                }}
                sx={{
                  '& .MuiOutlinedInput-root': {
                    '& fieldset': {
                      borderColor: '#6cc24a', // Cor da borda
                    },
                    '&:hover fieldset': {
                      borderColor: '#5cb041', // Cor da borda ao hover
                    },
                    '&.Mui-focused fieldset': {
                      borderColor: '#6cc24a', // Cor da borda ao focar
                    },
                  },
                }}
              />
            </Box>
            <Box mb={2}>
              <Button 
                type="submit" 
                variant="contained" 
                fullWidth
                sx={{ 
                  padding: 1, 
                  backgroundColor: '#6cc24a', 
                  color: '#fff', 
                  '&:hover': { backgroundColor: '#5cb041' },
                  textTransform: 'none', // Manter o texto no formato original
                  fontSize: '16px',
                  fontWeight: 'bold'
                }}
              >
                Entrar
              </Button>
            </Box>
          </form>
          <Box display="flex" justifyContent="center" mt={2}>
            <Link component={RouterLink} to="/auto-cadastro" variant="body2" sx={{ color: '#6cc24a' }}>
              Não tem uma conta? Cadastre-se aqui
            </Link>
          </Box>
        </Paper>
        {/* Adicionando créditos abaixo da caixa de login */}
        <Box
          sx={{
            position: 'absolute',
            bottom: 16,
            color: '#6cc24a',
            textAlign: 'center',
            width: '100%'
          }}
        >
          <Typography variant="body2">
            Versão do Sistema: 1.2.0
          </Typography>
          <Typography variant="body2">
            Suporte: (66) 98103-1470
          </Typography>
          <Typography variant="body2">
            Desenvolvido por <strong>IRYD</strong>
          </Typography>
        </Box>
        <Snackbar open={openSnackbar} autoHideDuration={6000} onClose={this.handleSnackbarClose}>
          <Alert onClose={this.handleSnackbarClose} severity={snackbarSeverity} sx={{ width: '100%' }}>
            {snackbarMessage}
          </Alert>
        </Snackbar>
      </Box>
    );
  }
}
