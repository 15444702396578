import React, { useState, useEffect } from 'react';
import axios from 'axios';
import jwtDecode from 'jwt-decode';
import {
    Box, TextField, Button, Typography, FormControl, CircularProgress,
    Snackbar, Alert, Paper, Modal, Grid, Card, CardMedia, CardContent, List, ListItem, ListItemText
} from '@mui/material';
import { Autocomplete } from '@mui/material';
import { useNavigate } from 'react-router-dom';

const logoGreen = "#6cc24a"; // Cor dos ícones da sidebar e botão
const cardBackground = "#ffffff"; // Cor de fundo do card
const textColor = "#333333"; // Cor do texto
const fontFamily = "'Poppins', sans-serif"; // Fonte Poppins

const SendMessage = () => {
    const [groups, setGroups] = useState([]);
    const [files, setFiles] = useState([]);
    const [caption, setCaption] = useState('');
    const [loading, setLoading] = useState(false);
    const [openSnackbar, setOpenSnackbar] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState('');
    const [userCities, setUserCities] = useState([]);
    const [selectedCity, setSelectedCity] = useState('');
    const [modalOpen, setModalOpen] = useState(false);
    const [progress, setProgress] = useState(0);

    const navigate = useNavigate();

    useEffect(() => {
        const fetchUserCities = async () => {
            try {
                const token = localStorage.getItem('token');
                const userId = jwtDecode(token).id;
                const response = await axios.get(`https://dev.zapfly.pro/user-cities/${userId}`, {
                    headers: {
                        'x-access-token': token
                    }
                });
                setUserCities(response.data);
            } catch (error) {
                console.error('Error fetching user cities:', error);
            }
        };

        fetchUserCities();
    }, []);

    useEffect(() => {
        if (selectedCity) {
            const fetchGroupsByCity = async () => {
                try {
                    const token = localStorage.getItem('token');
                    const response = await axios.get(`https://dev.zapfly.pro/groups-by-city/${selectedCity}`, {
                        headers: {
                            'x-access-token': token
                        }
                    });
                    setGroups(response.data);
                } catch (error) {
                    console.error('Error fetching groups by city:', error);
                }
            };

            fetchGroupsByCity();
        }
    }, [selectedCity]);

    const handleFileChange = (e) => {
        setFiles(Array.from(e.target.files));
    };

    const handleSendMessage = async () => {
        setLoading(true);
        setModalOpen(true);
    
        const selectedGroupIds = groups.map(group => group.group_id);
    
        if (selectedGroupIds.length === 0) {
            setSnackbarMessage('Nenhum grupo selecionado');
            setLoading(false);
            setOpenSnackbar(true);
            return;
        }
    
        const formData = new FormData();
        files.forEach((file) => {
            formData.append('files', file);
        });
        formData.append('groupIds', JSON.stringify(selectedGroupIds));
        formData.append('caption', caption);
    
        try {
            const token = localStorage.getItem('token');
            const uploadUrl = 'https://dev.zapfly.pro/upload-multiple-files';
    
            const uploadResponse = await axios.post(uploadUrl, formData, {
                headers: {
                    'x-access-token': token,
                    'Content-Type': 'multipart/form-data'
                }
            });
    
            if (uploadResponse.data.status === 'success') {
                // Simula o progresso da barra para 100%
                setProgress(100);
    
                setTimeout(() => {
                    setSnackbarMessage('Mensagem enviada com sucesso');
                    setModalOpen(false);
                    navigate('/history');
                }, 2000);
            } else {
                setSnackbarMessage('Erro ao enviar mensagem');
            }
        } catch (error) {
            setSnackbarMessage('Erro ao enviar mensagem');
            console.error('Error sending message:', error);
        }
    
        setLoading(false);
        setOpenSnackbar(true);
    };

    const handleSnackbarClose = () => {
        setOpenSnackbar(false);
    };

    return (
        <Box display="flex" flexDirection="column" alignItems="center" mt={4} p={2} width="100%">
            <Paper sx={{ padding: 4, boxShadow: 4, borderRadius: 2, maxWidth: 1200, width: '100%', backgroundColor: cardBackground }}>
                <Typography variant="h5" sx={{ fontFamily, color: textColor, fontWeight: 600, textAlign: 'center', mb: 3 }}>
                    Enviar Arquivo para Grupos
                </Typography>
                <Grid container spacing={4}>
                    <Grid item xs={12} md={6}>
                        <FormControl variant="outlined" margin="normal" fullWidth>
                            <Autocomplete
                                options={userCities}
                                getOptionLabel={(option) => option.city_name}
                                onChange={(event, newValue) => setSelectedCity(newValue ? newValue.id : '')}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        variant="outlined"
                                        label="Cidades"
                                        placeholder="Selecione a cidade"
                                        sx={{ fontFamily }}
                                    />
                                )}
                            />
                        </FormControl>
                        <TextField
                            label="Legenda"
                            value={caption}
                            onChange={(e) => setCaption(e.target.value)}
                            margin="normal"
                            variant="outlined"
                            fullWidth
                            multiline
                            rows={4}
                            sx={{ fontFamily }}
                        />
                        <input
                            accept="image/*,video/mp4,video/mkv,video/mov,video/avi,application/pdf,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document,application/vnd.ms-powerpoint,application/vnd.openxmlformats-officedocument.presentationml.presentation,application/vnd.ms-excel,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,audio/*"
                            style={{ display: 'none' }}
                            id="raised-button-file"
                            multiple
                            type="file"
                            onChange={handleFileChange}
                        />
                        <label htmlFor="raised-button-file">
                            <Button variant="contained" component="span" sx={{ backgroundColor: logoGreen, color: '#fff', mt: 2, fontFamily }}>
                                Escolher Arquivos
                            </Button>
                        </label>
                        <Typography variant="body2" color="textSecondary" sx={{ fontFamily, mt: 1 }}>
                            Formatos aceitos: Imagens, Vídeos (mp4, mkv, mov, avi), Áudios, Documentos (pdf, doc, docx, ppt, pptx, xls, xlsx)
                        </Typography>
                        <Typography variant="body2" color="textSecondary" sx={{ fontFamily, mt: 1 }}>
                            Tamanhos permitidos: Vídeos até 100MB, Documentos até 10MB
                        </Typography>
                    </Grid>

                    <Grid item xs={12} md={6}>
                        <Typography variant="h6" sx={{ fontFamily, color: textColor, fontWeight: 600 }}>
                            Prévia da Mensagem
                        </Typography>
                        <Card sx={{ maxWidth: 345, mt: 2, mb: 4 }}>
                            {files.length === 1 && files[0].type.startsWith('image/') && (
                                <CardMedia
                                    component="img"
                                    image={URL.createObjectURL(files[0])}
                                    alt="preview"
                                    sx={{ maxHeight: 200, objectFit: 'contain' }}
                                />
                            )}
                            {(files.length > 1 || files[0]?.type.startsWith('video/')) && (
                                <CardContent>
                                    <Typography variant="body2" color="textSecondary" component="p">
                                        Arquivos Selecionados:
                                    </Typography>
                                    <List>
                                        {files.map((file, index) => (
                                            <ListItem key={index}>
                                                <ListItemText primary={file.name} />
                                            </ListItem>
                                        ))}
                                    </List>
                                </CardContent>
                            )}
                            <CardContent>
                                <Typography variant="body2" color="textSecondary" component="p">
                                    {caption}
                                </Typography>
                            </CardContent>
                        </Card>
                    </Grid>
                </Grid>
                <Box position="relative" display="inline-flex" mt={2}>
                    <Button 
                        variant="contained" 
                        sx={{
                            backgroundColor: logoGreen,
                            color: '#fff',
                            fontFamily,
                            '&:hover': {
                                backgroundColor: '#5aa437',
                            },
                            minWidth: 160,
                            padding: '10px 20px',
                            fontWeight: 'bold'
                        }}
                        onClick={handleSendMessage}
                        disabled={loading}
                    >
                        Enviar Mensagem
                    </Button>
                    {loading && (
                        <CircularProgress
                            size={24}
                            sx={{
                                color: '#fff',
                                position: 'absolute',
                                top: '50%',
                                left: '50%',
                                marginTop: '-12px',
                                marginLeft: '-12px',
                            }}
                        />
                    )}
                </Box>
                <Snackbar open={openSnackbar} autoHideDuration={6000} onClose={handleSnackbarClose}>
                    <Alert onClose={handleSnackbarClose} severity={snackbarMessage.includes('Erro') ? 'error' : 'success'} sx={{ width: '100%' }}>
                        {snackbarMessage}
                    </Alert>
                </Snackbar>
                {/* Modal de progresso */}
                <Modal
                    open={modalOpen}
                    onClose={() => setModalOpen(false)}
                    aria-labelledby="modal-title"
                    aria-describedby="modal-description"
                >
                    <Box 
                        sx={{
                            position: 'absolute',
                            top: '50%',
                            left: '50%',
                            transform: 'translate(-50%, -50%)',
                            width: 400,
                            bgcolor: 'background.paper',
                            boxShadow: 24,
                            p: 4,
                            borderRadius: 2,
                            textAlign: 'center'
                        }}
                    >
                        <Typography id="modal-title" variant="h6" sx={{ fontFamily, mb: 2 }}>
                            Enviando Mensagem
                        </Typography>
                        <CircularProgress variant="determinate" value={progress} />
                        <Typography id="modal-description" sx={{ mt: 2, fontFamily }}>
                            {progress}% concluído
                        </Typography>
                        <Typography sx={{ mt: 2, fontFamily }}>
                            Seu envio está em andamento. Aguarde alguns momentos...
                        </Typography>
                    </Box>
                </Modal>
            </Paper>
        </Box>
    );
};

export default SendMessage;
